import { ClerkApp } from '@clerk/remix'
import { rootAuthLoader } from '@clerk/remix/ssr.server'
import { Heading, Toaster, Text } from '@optechai/design-system'
import {
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  redirect,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import '@upsolve-labs/common/styles/global.css'
import '@optechai/design-system/styles/global.css'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'

import { useUserMonitoring } from './services/user-monitoring.service'
import './services/db'

export const loader = (args: LoaderFunctionArgs) => {
  const url = new URL(args.request.url)
  if (import.meta.env.PROD && url.hostname !== 'app.lorikeetcx.ai') {
    url.hostname = 'app.lorikeetcx.ai'
    return redirect(url.toString(), { status: 301 })
  }

  return rootAuthLoader(args, (args) => {
    const cookie = args.request.headers.get('Cookie')
    return {
      isChristmas: cookie?.includes('christmas') ?? false,
    }
  })
}

export const links: LinksFunction = () => [
  {
    rel: 'icon',
    type: 'image/x-icon',
    href: import.meta.env.PROD
      ? 'https://assets.lorikeetcx.ai/web/favicon.ico'
      : '/favicon.ico',
  },
]

export const meta: MetaFunction = () => [
  { name: 'og:type', content: 'website' },
  { name: 'og:title', content: 'Lorikeet' },
  {
    name: 'og:description',
    content: 'AI-backed solution for managing, creating and editing workflows.',
  },
  { name: 'og:url', content: 'https://app.lorikeetcx.ai' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1' },
  {
    property: 'og:image',
    content: import.meta.env.PROD
      ? 'https://assets.lorikeetcx.ai/web/og-image.png'
      : '/og-image.png',
  },
]

function App() {
  useUserMonitoring()
  const { isChristmas } = useLoaderData<typeof loader>()
  return (
    <html
      className="rebrand h-full"
      data-theme={isChristmas ? 'christmas' : undefined}
      lang="en"
    >
      <head>
        <meta charSet="utf-8" />
        <script
          src={
            import.meta.env.PROD
              ? 'https://assets.lorikeetcx.ai/web/widget.js'
              : '/widget.js'
          }
          type="module"
        />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <Toaster />
      </body>
    </html>
  )
}

export default withSentry(ClerkApp(App))

/* 404 page */
const CustomBoundary = () => {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  if (isRouteErrorResponse(error)) {
    // If the user has hit a 401 due to Clerk's auth jank, offer them a redirect
    // to the workaround page that should unblock them.
    const suggestionText =
      error.status === 401 ? (
        <Text>
          Not logged in properly. Try{' '}
          <a href="https://accounts.lorikeetcx.ai/organization/">
            logging in here
          </a>
          .
        </Text>
      ) : (
        <Text>
          This page does not exist. Try going back <a href="/">home</a>.
        </Text>
      )
    return (
      <html className="rebrand">
        <head>
          <title>Page Not Found</title>
          <Meta />
          <Links />
        </head>
        <body>
          <div className="flex min-h-screen flex-col items-center justify-center py-2">
            <Heading variant="h1">{error.status}</Heading>
            <Heading variant="h2">{error.statusText}</Heading>
            {suggestionText}
          </div>
          <Scripts />
        </body>
      </html>
    )
  }

  return (
    <html className="rebrand">
      <head>
        <title>Server Error</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex min-h-screen flex-col items-center justify-center py-2">
          <Heading variant="h1">Server Error</Heading>
          <Text>
            The Lorikeet team have been notified. Please refresh and try again.
            If you're stuck, reach out or email{' '}
            <a href="mailto:support@lorikeetcx.ai">support@lorikeetcx.ai</a>
          </Text>
        </div>
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = CustomBoundary
